import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../components/Header";
import Appfooter from "../components/Appfooter";

class ProtectedLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      devtoolsOpen: false,
    };
  }
  componentDidMount = () => {
    // if (process.env.REACT_APP_ENV !== "development") {
    //   this.setState({ devtoolsOpen: devTools.isOpen }, () => {
    //     if (this.state.devtoolsOpen) {
    //       alert("DEVTOOLS AREN'T ALLOWED");
    //     }
    //   });
    //   window.addEventListener("devtoolschange", (event) => {
    //     this.setState({ devtoolsOpen: event.detail.isOpen }, () => {
    //       if (this.state.devtoolsOpen) {
    //         alert("DEVTOOLS AREN'T ALLOWED");
    //       }
    //     });
    //   });
    //   window.addEventListener("keydown", (event) => {
    //     if (event.keyCode === 73) {
    //       event.preventDefault();
    //       alert("DEVTOOLS AREN'T ALLOWED");
    //     }
    //     if (event.keyCode === 67) {
    //       event.preventDefault();
    //       alert("DEVTOOLS AREN'T ALLOWED");
    //     }
    //     if (event.keyCode === 123) {
    //       event.preventDefault();
    //       alert("DEVTOOLS AREN'T ALLOWED");
    //     }
    //   });
    // }
  };
  render() {
    return (
      <>
        {this.state.devtoolsOpen === false && (
          <div
            onContextMenu={(e) => {
              if (process.env.REACT_APP_ENV !== "development") {
                e.preventDefault();
              }
            }}
          >
            <Header>{this.props.children}</Header>
            <Appfooter />
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loggedUserInfo: state.authentication,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedLayout);
