import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Disclaimer from "../pages/modules/web/components/Disclaimer";

class SupportFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <Disclaimer></Disclaimer>;
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(SupportFooter);
export default withTranslation()(SupportFooter);
